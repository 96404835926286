<mat-toolbar class="mat-elevation-z6">
  <span class="app-title">
    <img src="/assets/img/menu-icon2.svg" alt="Code It logo" class="logo">
    Streamplify
  </span>
  <div class="bar-middle-container">
    <div class="bar-middle-tools">
      <div class="search-container" *ngIf="isLoggedIn|async">
        <mat-icon class="search-icon">search</mat-icon>
        <input type="search" class="search-input" placeholder="Search" [ngModel]="searchService.searchValue$ | async"
          (ngModelChange)="searchService.searchValue$.next($event)" />
      </div>


        <button (click)="openDrawerMovie()" *ngIf="isLoggedIn|async" class="add-movie-button" mat-stroked-button>
          <mat-icon class="add-icon">add</mat-icon> New movie
        </button>

    </div>

  </div>


<!--  <div class="notifications-block">-->
<!--    <button mat-icon-button *ngIf="isLoggedIn |async" (click)="toggleNotifications()" aria-label="Notifications"-->
<!--      class="button-notifications">-->
<!--      <mat-icon [matBadge]="this.notifications.length" [matBadgeHidden]="notifications.length === 0"-->
<!--        matBadgeColor="warn" matBadgeSize="small">notifications</mat-icon>-->
<!--    </button>-->
<!--    <div class="notification-list" *ngIf="displayNotifications">-->
<!--      <mat-list *ngIf="notifications.length > 0">-->
<!--        <mat-list-item *ngFor="let notification of notifications">-->
<!--          <div mat-line class="content">{{ notification.content }}</div>-->
<!--          <div mat-line class="date">{{ notification.createdAt|date:'d/m/Y h:m' }}</div>-->
<!--        </mat-list-item>-->
<!--      </mat-list>-->
<!--    </div>-->
<!--  </div>-->
  <div class="app-details" *ngIf="storageUsage !== null" matTooltip="Total storage used on the bucket on the {{ storageUsage.updatedAt | date:'medium' }}">
    <mat-icon>storage</mat-icon>
    {{ getHumanReadableStorage(this.storageUsage.numberOfMegabytes) }}
  </div>
  <span class="user-details" *ngIf="isLoggedIn |async">
    {{ username | async }}
    <button mat-icon-button (click)="logout()" aria-label="Log out">
      <mat-icon>logout</mat-icon>
    </button>
  </span>
</mat-toolbar>

<div class="app-container">
  <router-outlet></router-outlet>
</div>
