import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {INotificationDto} from '../dtos/notificationDto';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {

  private notifications: INotificationDto[] = [];
  public getNotifications = new Observable<INotificationDto[]>((observer) => {
    setInterval(() => {
      observer.next(this.notifications);
    }, 1000);
  });
  private urlBase = '/api/notifications';

  constructor(private http: HttpClient) {}

  /**
   * Use this service the get a list of jobs
   */
  /*getNotifications(): INotificationDto[] {
    // const url = this.urlBase;
    // return this.http.get<INotificationDto[]>(url);
    return this.notifications;
  }*/

  pushNotification(content: string): INotificationDto {
    const now = new Date();
    const notification: INotificationDto = {
      id: null,
      content,
      createdAt: now.toISOString(),
      link: null
    };
    this.notifications.unshift(notification);

    return notification;
  }
}
