import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IJobDto } from '../../dtos/jobDto';
import { UploadsService } from '../../services/uploads.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilmDataService } from '../../services/film-data-service';
import { IFilmDataDto } from '../../dtos/filmDataDto';
import { IFilmDataResultSetDto } from '../../dtos/filmDataResultSetDto';
import { debounceTime, switchMap } from 'rxjs/operators';
import { NotificationsService } from '../../services/notifications.service';
import MediaInfoFactory from 'mediainfo.js';
import { MediaInfo, ResultObject } from 'mediainfo.js/dist/types';
import { IVideoFormatDto } from 'src/app/dtos/videoFormatDto';
import { MediaInfoService } from '../../services/media.info.service';
import { IResolutionDto } from '../../dtos/resolutionDto';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilsService } from '../../services/utils.service';

interface Option {
  value: number;
  label: string;
}
@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent implements OnInit {
  @Output() uploadLaunchedEvent = new EventEmitter<boolean>();
  @Output() job = new EventEmitter<IJobDto>();
  public uploadStarted = false;
  public uploadProgress = 0;
  public filename = '';
  public fileToUpload: File = null;
  public jobForm: FormGroup;
  public matchingFilms: IFilmDataDto[];
  public imageUrl = environment.tmbdImageUrl;
  public imageQuerySize = 'w92';
  public videoInfo: IVideoFormatDto = null;
  public uploadError: string = null;
  private availableResolutions: IResolutionDto[] = [
    {
      uniqueName: 'Resolution270p',
      displayName: '270p',
      height: 270
    },
    {
      uniqueName: 'Resolution480p',
      displayName: '480p',
      height: 480
    },
    {
      uniqueName: 'Resolution720p',
      displayName: '720p',
      height: 720
    },
    {
      uniqueName: 'Resolution1080p',
      displayName: '1080p',
      height: 1080
    },
    {
      uniqueName: 'Resolution2160p',
      displayName: '4K',
      height: 2160
    }
  ];
  public filteredResolutions: IResolutionDto[] = [];
  public priorities: Option[] = [
    { label: 'Standard', value: 0 },
    { label: 'High', value: 25 },
    { label: 'Critical', value: 50 }
  ];
  constructor(
    private uploadsService: UploadsService,
    private filmDataService: FilmDataService,
    private notificationsService: NotificationsService,
    private mediaInfoService: MediaInfoService,
    private snackbar: MatSnackBar,
    public utils: UtilsService
  ) { }

  ngOnInit(): void {
    this.jobForm = new FormGroup({
      title: new FormControl('', Validators.required),
      hasDrm: new FormControl(true),
      resolutionList: new FormControl([], Validators.required),
      priority: new FormControl(0, Validators.required)
    });

    this.jobForm.get('title').valueChanges.pipe(
      debounceTime(400),
      switchMap(value => value !== '' ? this.filmDataService.getDataForFilm(value) : [])
    ).subscribe((data: IFilmDataResultSetDto) => {
      this.matchingFilms = data.results;
    });
  }

  /**
   * Checks that the form is correctly fulfilled and:
   * - Retrieves the uploads URLS from AWS
   * - Uploads the file sequentially
   * - finalize the upload
   * - updates the UI
   */
  async initUpload(): Promise<void> {
    /** If the form is not fulfilled correctly */
    if (this.jobForm.invalid || this.fileToUpload === null) {
      if (this.fileToUpload === null) {
        this.uploadError = 'You need to select a file';
      }
      this.jobForm.markAllAsTouched();
      return;
    }

    this.uploadStarted = true;
    this.uploadProgress = 0;
    this.videoInfo.partsCount = this.uploadsService.getNumberOfParts(this.videoInfo.fileSize);

    const job = this.createJobObject();
    /** Gets the upload URLs from AWS */
    const returnedData = await this.uploadsService.createJob(job).toPromise();
    const uploadUrlList = returnedData.uploadUrlList;

    /** Starts the upload and updates the interface */
    this.uploadsService.uploadParts(uploadUrlList, returnedData.job.id, this.fileToUpload).then(() => {
      this.uploadStarted = false;
      this.removeFile();
      const message = 'Your film ' + this.jobForm.get('title').value + ' has been sent for encryption.';
      this.notificationsService.pushNotification(message);
      this.snackbar.open(message, 'Close', { duration: 5000 });
    });
    this.uploadLaunchedEvent.emit(true);
  }

  /**
   * Create a job to be uploaded collecting the value from:
   * - The form
   * - the video info collected by the library
   * @private
   */
  private createJobObject(): IJobDto {
    return {
      id: undefined,
      status: undefined,
      input: undefined,
      title: this.jobForm.get('title').value,
      resourceId: undefined,
      creationDate: undefined,
      canAppend: false,
      customerId: undefined,
      encodingJobStatus: undefined,
      hasDrm: this.jobForm.get('hasDrm').value,
      durationInSeconds: this.videoInfo.durationInSeconds,
      fileSize: this.videoInfo.fileSize,
      format: this.videoInfo.format,
      frameCount: this.videoInfo.frameCount,
      frameRate: this.videoInfo.frameRate,
      height: this.videoInfo.height,
      partsCount: this.uploadsService.getNumberOfParts(this.videoInfo.fileSize),
      resolutionList: this.jobForm.get('resolutionList').value,
      uploadId: undefined,
      errorMessage: undefined,
      priority: this.jobForm.get('priority').value
    };
  }

  /**
   * When a file is selected we use the MediaInfo library to retrieve the technical details
   * of this film and updates the form with the available encoding options.
   */

  OnFileUpload(event: any): void {
    const files = event.target.files as FileList;
    this.onFileDropped(files);
  }
  async onFileDropped(event: any): Promise<void> {
    const file = event as FileList;
    this.uploadProgress = 0;
    this.uploadError = null;
    this.fileToUpload = file.item(0);

    MediaInfoFactory({ format: 'object' }, async (mediainfo: MediaInfo) => {
      const result = await this.mediaInfoService.getMetadata(mediainfo, this.fileToUpload) as ResultObject;
      console.log('result', result);
      this.videoInfo = this.mediaInfoService.FromResultObject(result);
      this.filteredResolutions = this.availableResolutions
        .filter((resolution: IResolutionDto) => resolution.height <= this.videoInfo.height);
      const valueList = this.filteredResolutions.map((resolution: IResolutionDto) => resolution.uniqueName);
      this.jobForm.patchValue({ resolutionList: valueList, title: this.fileToUpload.name });
    });
  }

  /**
   * Resets the upload form.
   */
  removeFile(): void {
    this.fileToUpload = null;
    this.videoInfo = null;
  }
}
