<div class="upload-file-container">
  <div class="file-button-container">
    <div class="dropzone" appDragAndDrop (fileDropped)="onFileDropped($event)" *ngIf="this.fileToUpload === null">
      <input
        class="cursor-pointer" 
        type="file" 
        id="fileUpload" 
        accept="video/*"
        (change)="OnFileUpload($event)" 
      />
      <h3>Please drop your movie file (.mp4, .mkv)</h3>
      <h3>or</h3>
      <label for="fileUpload">Click to browse the files on your computer</label>
    </div>

    <button mat-raised-button color="warn" *ngIf="this.fileToUpload !== null" class="remove-file"
      aria-label="Remove the movie" (click)="removeFile()">
      <mat-icon>delete</mat-icon> Remove file
    </button>
  </div>

  <div class="alert-error"
    *ngIf="this.fileToUpload !== null && this.videoInfo !== null && this.videoInfo.durationInSeconds === undefined">
    <mat-icon>warning</mat-icon>
    We were not able to analyze your file. Please verify you submitted a video file.
  </div>
  <div class="alert-error" *ngIf="this.uploadError !== null">
    <mat-icon>warning</mat-icon>
    {{ uploadError }}
  </div>
  <mat-list
    *ngIf="this.fileToUpload !== null && this.videoInfo !== null && this.videoInfo.durationInSeconds !== undefined">
    <mat-list-item>
      <mat-icon mat-list-icon>file_present</mat-icon>
      <div mat-line>
        <h3>File name</h3>
      </div>
      <div mat-line>{{ fileToUpload.name }}</div>
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon>height</mat-icon>
      <div mat-line>
        <h3>Video Height</h3>
      </div>
      <div mat-line>{{ videoInfo.height }}</div>
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon>access_time</mat-icon>
      <div mat-line>
        <h3>Duration</h3>
      </div>
      <div mat-line>{{ utils.formatSeconds(videoInfo.durationInSeconds) }}</div>
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon>compress</mat-icon>
      <div mat-line>
        <h3>Format</h3>
      </div>
      <div mat-line>{{ videoInfo.format }}</div>
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon>note</mat-icon>
      <div mat-line>
        <h3>Frame count</h3>
      </div>
      <div mat-line>{{ videoInfo.frameCount }}</div>
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon>local_movies</mat-icon>
      <div mat-line>
        <h3>Frame rate</h3>
      </div>
      <div mat-line>{{ videoInfo.frameRate }}</div>
    </mat-list-item>
  </mat-list>
</div>
<form [formGroup]="jobForm">
  <div *ngIf="fileToUpload !== null">
    <p>
      <mat-form-field appearance="standard" class="search-field">
        <mat-label>Film Name</mat-label>
        <input formControlName="title" matInput placeholder="Start typing to search..." [matAutocomplete]="auto">
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of matchingFilms" [value]="option.title" class="option-film-container">
            <span class="option-film">
              <span *ngIf="option.poster_path === null" class="cover-substitute"></span>
              <img *ngIf="option.poster_path !== null" src="{{ imageUrl + imageQuerySize + '/' + option.poster_path}}"
                alt="{{ 'Cover for ' + option.title}}">
              <span class="film-details">
                <span class="film-title">{{ option.title }}</span>
                <span class="film-release">{{ option.release_date }} - {{ option.original_title }}</span>
              </span>
            </span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="jobForm.get('title').hasError('required')">
          Film name is required
        </mat-error>
      </mat-form-field>
    </p>

    <p *ngIf="fileToUpload !== null">
      <mat-selection-list #resolutions formControlName="resolutionList">
        <mat-list-option *ngFor="let resolution of filteredResolutions" [value]="resolution.uniqueName">
          {{ resolution.displayName }}
        </mat-list-option>
        <mat-error *ngIf="jobForm.get('resolutionList').hasError('required')">
          You need to pick at least one resolution.
        </mat-error>
      </mat-selection-list>
    </p>
    <p>
      <mat-slide-toggle color="primary" formControlName="hasDrm">DRM Encryption</mat-slide-toggle>
    </p>
    <p>
      <mat-form-field class="form-field-priority" appearance="fill">
        <mat-label>Priority</mat-label>
        <mat-select class="option-priority" formControlName="priority" [(ngModel)]="priorities[0].value" name="priority">
          <mat-option *ngFor="let priority of priorities" [value]="priority.value">
            {{priority.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </p>
  </div>
</form>

<button type="button" mat-raised-button color="primary" (click)="initUpload()" [disabled]="uploadStarted">
  {{ uploadStarted ? 'Uploading...' : 'Upload' }}
</button>