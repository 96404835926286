// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendApiUrl: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port,
  s3OutputDirectory: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/video/',
  fairplayCertUrl: '/assets/drm/fairplay.cer',
  // widewineProxyUrl: 'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=8A9C09', //Code It Proxy
  widewineProxyUrl: 'https://widevine-dash.ezdrm.com/proxy?pX=DC0B10', // FSPro proxy
  playreadyPreAuthUrl: 'https://playready.ezdrm.com/cency/preauth.aspx?pX=6AD036',
  fairplayLicenseUrl: 'https://fps.ezdrm.com/api/licenses/',
  tmbdImageUrl: 'https://image.tmdb.org/t/p/',
  tmbdApiUrl: 'https://api.themoviedb.org/3/',
  cognitoUserPoolId: 'eu-west-1_SqWLiRab8',
  cognitoClientId: '7tej8tia64pd8dq0dbv8ck1co2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
