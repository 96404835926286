import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlayerComponent } from '../../component/player/player.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-player-page',
  templateUrl: './player-page.component.html',
  styleUrls: ['./player-page.component.scss']
})
export class PlayerPageComponent implements OnInit {

  private jobUniqueId: string = null;
  private error: string = null;

  constructor(private route: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.jobUniqueId = this.route.snapshot.paramMap.get('uniqueId');

    if (this.jobUniqueId !== null) {
      this.dialog.open(PlayerComponent, {
        data: {
          resourceId: this.jobUniqueId
        },
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw'
      });
    } else {
      this.error = 'You did not provide a unique ID';
    }
  }
}
