import { Injectable } from '@angular/core';
import { MediaInfo, ReadChunkFunc, Result } from 'mediainfo.js/dist/types';
import { IVideoFormatDto } from '../dtos/videoFormatDto';

@Injectable({
  providedIn: 'root'
})

export class MediaInfoService {

  constructor() { }

  /**
   * Use this service to retrieve the movie file details
   */
  async getMetadata(mediainfo: MediaInfo, file: File): Promise<Result> {
    if (!file) {
      throw new Error('Cannot retrieve media information');
    }

    const getSize = () => file.size;

    const readChunk: ReadChunkFunc = (chunkSize, offset) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          if (event.target.error) {
            reject(event.target.error);
          }
          resolve(new Uint8Array(event.target.result as ArrayBuffer));
        };
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
      });

    return await mediainfo.analyzeData(getSize, readChunk) as Result;
  }

  FromResultObject = (input: any): IVideoFormatDto => {

    const videoFormat: IVideoFormatDto = {} as IVideoFormatDto;
    for (const track of input.media.track) {

      if (track['@type'] === 'General') {
        videoFormat.durationInSeconds = Number(track.Duration);
        videoFormat.format = track.Format;
        videoFormat.frameCount = Number(track.FrameCount);
        videoFormat.frameRate = Number(track.FrameRate);
        videoFormat.fileSize = Number(track.FileSize);
      }
      else if (track['@type'] === 'Video') {
        videoFormat.height = Number(track.Height);
        if (isNaN(videoFormat.frameCount)) {
          videoFormat.frameCount = Number(track.FrameCount);
        }
      }
    }

    if (isNaN(videoFormat.frameCount)) {
      videoFormat.frameCount = 0;
    }

    return videoFormat;
  }
}
