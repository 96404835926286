import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilsService  {
  constructor() { }

  formatSeconds(numberOfSeconds: number): string {
    const hours = Math.floor(numberOfSeconds / 60 / 60);
    const minutes = Math.floor(numberOfSeconds / 60) - (hours * 60);
    const seconds = Math.floor(numberOfSeconds % 60);
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
  }
}
