<h2 mat-dialog-title>Forgot your password?</h2>
<p>Don't worry! Just fill in your username and we'll send you a code to reset your password</p>
<div mat-dialog-content class="mat-typography">
  <p>
    <mat-form-field class="username">
      <mat-label>Username</mat-label>
      <input [(ngModel)]="username" matInput type="text">
    </mat-form-field>

  <form>

    <mat-error *ngIf="false">
      You must upload the same file!
    </mat-error>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button (click)="cancel()">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-flat-button color="primary" (click)="validate()">Validate</button>
</div>