import { ElementRef } from "@angular/core";

declare let shaka: any; // shaka is defined globaly

export interface ISize {
  width: number;
  height: number;
}


export interface ISprite {
  timecode: number;
  x: number;
  y: number;
}


export interface IPreviewInfo {
  sprite: ISize;
  sprites: Array<ISprite>;
}


/**
 * The factory class to use a FspSeekBar in the player
 */
export class PlayerSeekBarFactory {

  previewInfo: IPreviewInfo;
  previewImageUrl: string;

  constructor(previewInfo: IPreviewInfo, previewImageUrl: string) {
    this.previewImageUrl = previewImageUrl;
    this.previewInfo = previewInfo;
  }

  create(root: any, ctrls: any): any {

    /**
     * As this class inherits from an element from the shaka library it seems that we have to declare it inside this create
     * function to avoid a "shaka not defined" error
     */
    class PlayerSeekBar extends shaka.ui.SeekBar {

      previewInfo: IPreviewInfo;
      previewImageUrl: string;

      isPositionChanging = false;
      seekBarElt: any;

      previewContainerElt: HTMLDivElement = null;
      timeContainerElt: HTMLDivElement = null;
      imageContainerElt: HTMLImageElement = null;

      constructor(rootElement: HTMLElement, controls: any, previewInfo: IPreviewInfo, previewImageUrl: string) {

        super(rootElement, controls);

        this.previewImageUrl = previewImageUrl;
        this.previewInfo = previewInfo;
        this.seekBarElt = rootElement.querySelector('input.shaka-seek-bar');

        this.createPreviewElement();

        this.seekBarElt.onmousemove = (event: any) => this.mouseMove(event); // this.debounce((event) => this.mouseMove(event),10,false);
        this.seekBarElt.onmouseout = (event: any) => this.mouseOut(event);
        this.seekBarElt.onmouseover = (event: any) => this.mouseOver(event);
        this.seekBarElt.onmouseup = (event: any) => this.mouseUp(event);
        this.seekBarElt.onmouseclick = (event: any) => event.preventDefault();
        this.seekBarElt.onmousedown = (event: any) => this.mouseDown(event);
      }

      debounce = (func: any, wait: any, immediate: any) => {
        let timeout: NodeJS.Timeout;
        return () => {
          const context = this;
          const args = arguments;
          const later = () => {
            timeout = null;
            if (!immediate) {
              func.apply(context, args);
            }
          };
          const callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) { func.apply(context, args); }
        };
      };

      createPreviewElement() {

        if (!this.previewInfo) {
          return;
        }

        this.previewContainerElt = document.createElement('div');
        this.previewContainerElt.classList.add('preview-container');
        this.previewContainerElt.style.height = this.previewInfo.sprite.height.toString() + 'px';
        this.previewContainerElt.style.width = this.previewInfo.sprite.width.toString() + 'px';
        this.previewContainerElt.style.top = (-this.previewInfo.sprite.height - 10) + 'px';
        this.previewContainerElt.style.backgroundImage = 'url("' + this.previewImageUrl + '")';

        this.timeContainerElt = document.createElement('div');
        this.timeContainerElt.classList.add('preview-time');

        this.imageContainerElt = document.createElement('img');

        this.previewContainerElt.appendChild(this.timeContainerElt);
        this.previewContainerElt.appendChild(this.imageContainerElt);

        this.seekBarElt.parentElement.appendChild(this.previewContainerElt);

      }

      calcSliderPosFromEvent(e: any): number {
        let position = (e.offsetX / e.target.clientWidth) * parseInt(e.target.getAttribute('max'), 10);
        const max = this.seekBarElt.max;

        position = Math.min(max, position);
        position = Math.max(position, 0);

        return position;
      }

      showPreview() {

        if (!this.previewContainerElt) {
          return;
        }
        this.previewContainerElt.style.display = 'block';
      }

      hidePreview() {

        if (!this.previewContainerElt) {
          return;
        }
        this.previewContainerElt.style.display = 'none';
      }

      findSpriteForPosition(position: any): ISprite {
        let currentSprite: ISprite = null;
        for (const s of this.previewInfo.sprites) {
          if (s.timecode < position) {
            currentSprite = s;
          }
          else if (s.timecode > position) {
            break;
          }
        }

        return currentSprite;
      }

      updatePreview(e: any, position: any) {

        if (!this.previewContainerElt) {
          return;
        }

        const sprite: ISprite = this.findSpriteForPosition(position);
        if (!sprite) {
          return;
        }

        const x = e.offsetX;
        this.previewContainerElt.style.left = x - this.previewInfo.sprite.width / 2 + 'px'; //'calc(' + x + 'px - ' + this.previewInfo.sprite.width / 2 + 'px )';

        this.previewContainerElt.style.backgroundPosition =
          -sprite.x * this.previewInfo.sprite.width + 'px ' + -sprite.y * this.previewInfo.sprite.height + 'px';

        const text = new Date(position * 1000).toISOString().substr(11, 8);
        this.timeContainerElt.innerText = text;
      }

      mouseDown(e: any) {
        this.isPositionChanging = true;
        e.preventDefault();
      }

      mouseOut(e: any) {
        this.hidePreview();
      }

      mouseOver(e: any) {
        this.showPreview();
      }

      mouseMove(e: any) {

        const position = this.calcSliderPosFromEvent(e);

        this.updatePreview(e, position);

        if (this.isPositionChanging) {
          this.video.currentTime = position;
        }

      }

      mouseUp(e: any) {

        const position = this.calcSliderPosFromEvent(e);
        this.video.currentTime = position;

        this.isPositionChanging = false;
      }

      setValue(value: any) {
        shaka.ui.SeekBar.prototype.setValue.call(this, value);
      }

    }

    return new PlayerSeekBar(root, ctrls, this.previewInfo, this.previewImageUrl);
  }

}
