import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-forgotten-password-modal',
  templateUrl: './forgotten-password-modal.component.html',
  styleUrls: ['./forgotten-password-modal.component.scss']
})
export class ForgottenPasswordModalComponent implements OnInit {

  public username: string = "";

  constructor(public dialogRef: MatDialogRef<ForgottenPasswordModalComponent>) {
  }

  ngOnInit(): void {
  }

  validate() {
    this.dialogRef.close(this.username);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
