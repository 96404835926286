<h2 mat-dialog-title>Resume the upload</h2>
<div mat-dialog-content class="mat-typography">
  <form [formGroup]="appendUploadForm">
    <p>Please pick the exact same file in order to resume the upload.</p>
    <p>Here is the name of your file: <strong>{{ data.storedJob.filename }}</strong></p>
    <input hidden (change)="onFileSelected($event)" formControlName="file" #fileInput type="file" id="file" />
    <div class="button-container">
      <button type="button" mat-raised-button (click)="fileInput.click()" class="choose-file">Choose File</button>
    </div>
    <mat-error *ngIf="appendUploadForm.get('file').hasError('pattern')">
      You must upload the same file!
    </mat-error>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close="false">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-flat-button [disabled]="appendUploadForm.invalid" color="primary" (click)="appendUpload()">Resume upload</button>
</div>
