
<div data-shaka-player-container >
  <!-- The data-shaka-player tag will make the UI library use this video element.
    If no video is provided, the UI will automatically make one inside the container div. -->
  <video data-shaka-player id="video" poster="{{ posterUri }}"  autoplay></video>
</div>


<button mat-icon-button mat-dialog-close *ngIf="data.displayCloseButton" class="player-close" aria-label="Close the video player">
  <mat-icon>close</mat-icon>
</button>
