import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
    providedIn: 'root'
})
export class DrawerService {
    private _matDrawer: MatDrawer;


    public setDrawer(matDrawer: MatDrawer) {
        this._matDrawer = matDrawer;
    }

    public open() {
        if (this._matDrawer) {
            return this._matDrawer.open();
        }
        return null;
    }


    public close() {
        if (this._matDrawer) {
            return this._matDrawer.close();
        }
        return null;
    }

    public toggle(): void {
        if (this._matDrawer) {
            this._matDrawer.toggle();
        }
        return null;
    }
}