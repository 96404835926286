import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThrowStmt } from '@angular/compiler';
import { MatDialog } from '@angular/material/dialog';
import { ForgottenPasswordModalComponent } from 'src/app/component/forgotten-password-modal/forgotten-password-modal.component';

enum FormType {
  Login,
  ResetPassword,
  ResetPasswordWithCode
}


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public usernameToReset = '';
  public newPassword = '';
  public loginForm: FormGroup;
  public changePasswordForm: FormGroup;
  private dataResetPassword: any;
  public forgottenPasswordForm: FormGroup;
  public currentForm = FormType.Login;
  public FormType = FormType;


  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.displayLoginForm();
    this.changePasswordForm = new FormGroup({
      password: new FormControl('', Validators.required)
    });
  }

  authenticate(): void {
    const username = this.loginForm.get('username').value;
    const password = this.loginForm.get('password').value;
    this.authenticateWithParams(username, password);
  }

  authenticateWithParams(username: string, password: string) {

    this.authenticationService.login(username, password)
      .then(() => {
        this.router.navigate(['/job-list']).then(() => {
          this.snackbar.open('Welcome back ' + username, 'Close', {
            duration: 3000,
          });
        });
      })
      .catch((reason) => {
        if (typeof reason === 'object' && 'userAttributes' in reason) {
          this.currentForm = FormType.ResetPassword;
          this.dataResetPassword = reason;
        } else {
          this.loginForm.get('password').setErrors({ system: reason });
        }
      });
  }

  displayResetPasswordWithCodeForm() {
    this.forgottenPasswordForm = new FormGroup({
      code: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      usernameToReset: new FormControl(this.usernameToReset)
    });

    this.currentForm = FormType.ResetPasswordWithCode;
  }

  displayLoginForm() {
    this.currentForm = FormType.Login;
    this.loginForm = new FormGroup({
      username: new FormControl(this.usernameToReset, Validators.required),
      password: new FormControl(this.newPassword, Validators.required)
    });
  }


  resetPassword(): void {
    const newPassword = this.changePasswordForm.get('password').value;
    this.authenticationService.resetPassword(newPassword, this.dataResetPassword)
      .then((userSession) => {
        this.router.navigate(['/']);
      })
      .catch((error) => console.log(error));
  }

  resetPasswordWithCode(): void {

    const newPassword = this.forgottenPasswordForm.get('newPassword').value;
    const verificationCode = this.forgottenPasswordForm.get('code').value;

    this.usernameToReset = this.forgottenPasswordForm.get('usernameToReset').value;


    this.authenticationService.resetPasswordWithCode(this.usernameToReset, verificationCode, newPassword)
      .then(() => {
        this.authenticateWithParams(this.usernameToReset, newPassword);
      })
      .catch((error) => console.log(error));
  }


  forgottenPassword() {

    const dialogRef = this.dialog.open(ForgottenPasswordModalComponent, {
      width: '60w'
    });

    dialogRef.afterClosed().subscribe(username => {
      if (username != null) {
        this.usernameToReset = username;
        this.authenticationService.forgotPassword(username).then(() => this.displayResetPasswordWithCodeForm()).catch(reason => alert("Sorry we could not reset your password"));
      }
    });
  }

}
