import {Component, OnInit} from '@angular/core';
import { INotificationDto } from './dtos/notificationDto';
import { NotificationsService } from './services/notifications.service';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SearchService } from './services/search.service';
import { DrawerService } from './services/mat-drawer.service';
import { IStorageUsageDto } from './dtos/storageUsageDto';
import { JobsService } from './services/jobs.service';
import { DecimalPipe } from '@angular/common';

declare var shaka: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isLoggedIn: Observable<boolean>;
  public username: Observable<string>;
  public displayNotifications = false;
  public storageUsage: IStorageUsageDto = null;
  /*public storageUsage: IStorageUsageDto = {
    updatedAt: '2022-06-04',
    numberOfMegabytes: 50
  };*/

  constructor(
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private jobService: JobsService,
    private router: Router,
    private snackbar: MatSnackBar,
    public searchService: SearchService,
    private drawerService: DrawerService,
    private decimalPipe: DecimalPipe
  ) {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.username = this.authenticationService.getUsername();
  }

  notifications: INotificationDto[] = [];

  ngOnInit(): void {


    shaka.polyfill.installAll();

    // this.notificationsService.getNotifications.subscribe((data) => { this.notifications = data; });
    this.jobService.getUsedStorage().subscribe((storageUsage) => this.storageUsage = storageUsage);
  }

  logout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']).then(() => {
      this.snackbar.open('See you soon!', 'Close', {
        duration: 3000,
      });
    });
  }

  openDrawerMovie(): void {
    this.drawerService.open();
  }

  toggleNotifications(): void {
    this.displayNotifications = !this.displayNotifications;
  }

  /**
   * Gets the number of megabytes in a human-readable way
   */
  getHumanReadableStorage(numberOfMegabytes: number): string {
    if (numberOfMegabytes < 1024) {
      return this.decimalPipe.transform(numberOfMegabytes, '1.2-2', 'en-US') + 'Mb';
    } else if (numberOfMegabytes < (1024 * 1024)) {
      return this.decimalPipe.transform((numberOfMegabytes / 1024), '1.2-2', 'en-US') + 'Gb';
    } else {
      return this.decimalPipe.transform((numberOfMegabytes / 1024) / 1024, '1.2-2', 'en-US') + 'Tb';
    }
  }
}
