import { Component, OnInit } from '@angular/core';
import { FilmDataService } from '../../services/film-data-service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PlayerComponent } from '../../component/player/player.component';
import { JobsService } from '../../services/jobs.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-film-list-test-page',
  templateUrl: './film-list-test-page.component.html',
  styleUrls: ['./film-list-test-page.component.scss']
})
export class FilmListTestPageComponent implements OnInit {

  private imageQuerySize = 'w1280';
  private resourceId: string;
  private s3InputFolderUrl = 's3://stream-it-test/input/';
  public integrationLink = '<script scr="myscript.js"></script>';
  public integrationScript = '<div class="stream-me" ressource="yourID"></div>';
  public movies = [
    {
      internationalTitle: 'Pulp Fiction',
      createdAt: '2020-03-03T20:34:32',
      fileSize: 20000000,
      synopsis: '',
      poster: '',
      releaseDate: ''
    },
    {
      internationalTitle: 'Natural Born Killers',
      createdAt: '2020-02-23T18:34:32',
      fileSize: 50000000,
      synopsis: '',
      poster: '',
      releaseDate: ''
    },
    {
      internationalTitle: 'Lethal Weapon',
      createdAt: '2020-02-24T18:34:32',
      fileSize: 70000000,
      synopsis: '',
      poster: '',
      releaseDate: ''
    },
    {
      internationalTitle: 'Die Hard',
      createdAt: '2020-02-17T21:34:32',
      fileSize: 60000000,
      synopsis: '',
      poster: '',
      releaseDate: ''
    }
  ];

  constructor(
    private filmDataService: FilmDataService,
    private datePipe: DatePipe,
    private jobsService: JobsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.movies.map((movie) => {
      this.filmDataService.getDataForFilm(movie.internationalTitle).subscribe(
        (data) => {
          const firstResult = data.results[0];
          const createdAt = new Date(movie.createdAt);
          movie.createdAt = this.datePipe.transform(createdAt, 'dd/MM/yyyy');
          movie.synopsis = firstResult.overview;
          movie.releaseDate = firstResult.release_date;
          movie.poster = environment.tmbdImageUrl + this.imageQuerySize + '/' + firstResult.backdrop_path;
        }
      );
    });

    this.jobsService.getJobsPaginated(0, 10).subscribe((jobs) => {
      if (jobs.length > 0) {
        const firstJob = jobs[0];
        const input = firstJob.input;
        this.resourceId = input.replace(this.s3InputFolderUrl, '');
      }
    });
  }

  openPlayer(poster: string): void {
    this.dialog.open(PlayerComponent, {
      data: {
        resourceId: this.resourceId,
        cover: poster
      },
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw'
    });
  }
}
