import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { IS3UploadUrlDto } from '../dtos/S3UploadUrlDto';
import { environment } from '../../environments/environment';
import { PartETag } from '../dtos/partETagDto';
import { catchError, concatMap, finalize, map } from 'rxjs/operators';
import { IndexedResponse } from '../component/upload-form/indexedResponse';
import { IUploadJobResponse } from '../dtos/UploadJobResponse';
import { storedJobStatusDto } from '../dtos/storedJobStatusDto';
import { storedIndexUrlDto } from '../dtos/storedIndexUrlDto';
import { IJobDto } from '../dtos/jobDto';

const CHUNK_SIZE = 5 * 1024 * 1024;
const storageKeyJobStatus = 'jobStatuses';

@Injectable({
  providedIn: 'root'
})
export class UploadsService {

  private jobUrlBase = environment.backendApiUrl + '/api/jobs';
  public uploadProgressesChange: Subject<IUploadJobResponse> = new Subject();
  public uploadsInProgress = 0;

  constructor(private http: HttpClient) { }

  /**
   * Use to get the url to upload the files
   */
  createJob(job: IJobDto): Observable<IS3UploadUrlDto> {
    const url = this.jobUrlBase;
    return this.http.post<IS3UploadUrlDto>(url, job);
  }

  /**
   * Use this service to upload a single file on the S3 input folder
   */
  uploadFile(blob: Blob, uploadUrl: string): Observable<any> {
    return this.http.put(uploadUrl, blob, { reportProgress: true, observe: 'response' });
  }

  /**
   * Completes the upload on S3. WARNING, if this url is not called then the file stays as chunk on AWS.
   */
  completeUpload(jobId: number, partEtags: PartETag[]): Observable<any> {
    const url = this.jobUrlBase + '/' + jobId + '/complete-upload';
    return this.http.post<any>(url, partEtags);
  }

  getStoredJobStatus(urls: string[], jobId: number, fileToUpload: File, uploadedId: number = null, partETags: PartETag[] = []): storedJobStatusDto {
    let storedIndexUrl: storedIndexUrlDto[];
    if (uploadedId === null) {
      // We initiate the URL array with all uploaded to false
      storedIndexUrl = urls.map((url, index) => {
        return {
          url: url,
          partNumber: index + 1,
          uploaded: false
        } as storedIndexUrlDto;
      });
    } else {
      // Otherwise, we update the array with the upload part status
      const jobs: storedJobStatusDto[] = JSON.parse(localStorage.getItem(storageKeyJobStatus));
      if (!jobs) {
        console.error('no jobs stored');
      }
      const job = jobs.find(item => item.id === jobId);
      if (!job) { console.error('no job found'); }
      storedIndexUrl = job.urls;
      if (uploadedId) {
        const url = storedIndexUrl.find(item => item.partNumber === uploadedId);
        url.uploaded = true;
      }
    }

    return {
      id: jobId,
      urls: storedIndexUrl,
      filename: fileToUpload.name,
      partETags: partETags
    };
  }
  /**
   * We have requested several url to AWS to upload the file : fileSize / CHUNK_SIZE.
   * We will create chunks and put them to those URLs one after the other.
   * This is why we are using the concatMap.
   * Once the upload is completed (last chunk uploaded) then we call the complete URL
   * So AWS will make the file available on S3.
   * While uploading we are updating the dictionary containing the upload progress.
   */
  uploadParts(urls: string[], jobId: number, fileToUpload: File, startFrom: number = null, partETags: PartETag[] = []): Promise<boolean> {
    const storedJobStatus: storedJobStatusDto = this.getStoredJobStatus(urls, jobId, fileToUpload, startFrom);
    this.storeJobStatus(storedJobStatus, true);
    this.uploadsInProgress++;

    return new Promise((resolve, reject) => {
      this.updateUploadProgress(jobId, urls.length, 0);
      const browseUrls = new Observable((subscriber) => {
        urls.forEach((url, index) => {
          subscriber.next({ index, url });
        });
        subscriber.complete();
      });

      browseUrls.pipe(
        concatMap((objectUrl: any) => {

          const partNumber = objectUrl.index + 1;
          if (startFrom && partNumber < startFrom) {
            return of(null);
          }
          const start = objectUrl.index * CHUNK_SIZE;
          const end = partNumber * CHUNK_SIZE;
          const blob = objectUrl.index < urls.length ? fileToUpload.slice(start, end) : fileToUpload.slice(start);

          return this.uploadFile(blob, objectUrl.url).pipe(map((resp: HttpResponse<any>) => {
            return { partNumber: partNumber, response: resp } as IndexedResponse<any>;
          }));
        }),
        catchError((error) => {
          console.error('Error', error);
          reject(false);
          return of(error);
        }),
        finalize(() => {
          this.completeUpload(jobId, partETags).subscribe(() => {
            this.uploadProgressesChange.next({
              id: jobId,
              uploadedParts: partETags.length,
              totalParts: urls.length,
              percent: Math.ceil((partETags.length / urls.length) * 100)
            });
            this.clearJobInStorage(jobId);
            this.uploadsInProgress--;
            resolve(true);
          });
        })
      ).subscribe((resp: IndexedResponse<any>) => {
        console.log('response', resp);
        if (resp === null) return;
        this.updateUploadProgress(jobId, urls.length, resp.partNumber);

        partETags.push({ partNumber: resp.partNumber, eTag: resp.response.headers.get('etag').replace('"', '') });
        const storedJob: storedJobStatusDto = this.getStoredJobStatus(urls, jobId, fileToUpload, resp.partNumber, partETags);
        this.storeJobStatus(storedJob);
      });
    });
  }

  /**
   * Divides the file size by the chunk size and return the number of parts (so the number of URLs needed).
   */
  getNumberOfParts(fileSize: number): number {
    return Math.ceil(fileSize / CHUNK_SIZE);
  }

  /**
   * Curates the dictionary with the uploaded parts for each job.
   */
  updateUploadProgress(jobId: number, total: number, uploaded: number): void {
    this.uploadProgressesChange.next({
      id: jobId,
      uploadedParts: uploaded,
      totalParts: total,
      percent: Math.ceil((uploaded / total) * 100)
    });
  }

  storeJobStatus(job: storedJobStatusDto, force: boolean = false): void {

    // In order to append a job we need the first url to be uploaded otherwise the other urls will be expired
    if (!job.urls[0].uploaded && !force) { return; }

    const jobs: storedJobStatusDto[] = JSON.parse(localStorage.getItem(storageKeyJobStatus)) || [];

    const storedJob = jobs.find(item => item.id === job.id);
    if (storedJob) {
      Object.assign(storedJob, job);
    } else {
      jobs.push(job);
    }

    localStorage.setItem(storageKeyJobStatus, JSON.stringify(jobs));
  }

  getJobStatus(): storedJobStatusDto[] {
    return JSON.parse(localStorage.getItem(storageKeyJobStatus)) || [];
  }

  clearJobInStorage(jobId: number): void {
    let jobs: storedJobStatusDto[] = JSON.parse(localStorage.getItem(storageKeyJobStatus)) || [];

    if (!jobs) { return; }
    let job = jobs.find((item: storedJobStatusDto) => { return item.id === jobId });
    jobs.splice(jobs.indexOf(job, 1));

    localStorage.setItem(storageKeyJobStatus, JSON.stringify(jobs));
  }
}
