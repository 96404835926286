import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IJobDto } from '../dtos/jobDto';
import { environment } from '../../environments/environment';
import { map, shareReplay, timeout } from 'rxjs/operators';
import { UploadsService } from './uploads.service';
import { storedJobStatusDto } from '../dtos/storedJobStatusDto';
import { ITokenDto } from '../dtos/tokenDto';
import { IStorageUsageDto } from '../dtos/storageUsageDto';

@Injectable({
  providedIn: 'root'
})

export class JobsService {

  private urlBase = environment.backendApiUrl + '/api/jobs';
  // TODO pagination
  subject = new Subject<IJobDto[]>();
  obs = this.subject.asObservable();

  constructor(private http: HttpClient, private uploadService: UploadsService) { }

  isApple(): boolean {
    return !!navigator.vendor && navigator.vendor.includes('Apple');
  }

  /**
   * Use this service the get a list of jobs
   */
  public getJobs(): Observable<IJobDto[]> {

    const url = this.urlBase;
    return this.http.get<IJobDto[]>(url).pipe(map(data => {
      return data.map((job: IJobDto) => this.enhanceJob(job));
    }));
  }

  getJobsPaginated(from: number, take: number, sortBy: string = '-CreationDate', filter: {} = null): Observable<IJobDto[]> {
    let url = this.urlBase + `?start=${from}&limit=${take}&sort=${sortBy}`;
    if (filter && Object.keys(filter).length) {
      for (const [key, value] of Object.entries(filter)) {
        if (value) {
          url += `&${key}=${value}`;
        }
      }
    }
    return this.http.get<IJobDto[]>(url).pipe(map(result => result.map(job => this.enhanceJob(job))));
  }

  getJobById(resourceId: number) {
    const url = this.urlBase + '/' + resourceId;
    return this.http.get<IJobDto>(url).pipe(shareReplay(), map(result => this.enhanceJob(result)));
  }

  private enhanceJob(job: IJobDto) {
    /** If a job is Created for more than 10 minutes we consider it is probably an error */
    const nowTimestamp = new Date().getTime();
    const tenMinutesAgo = new Date(nowTimestamp - (10 * 60 * 1000)).getTime() / 1000;

    job.creationDate = new Date(job.creationDate + 'Z');
    if ((new Date(job.creationDate).getTime()) < tenMinutesAgo && job.status === 'Created' && !job.canAppend) {
      job.status = 'Error';
    }
    return job;
  }

  getMovieToken(jobId: string) {
    return this.http.get<ITokenDto>(`${this.urlBase}/${jobId}/player-token`);
  }

  getUsedStorage() {
    return this.http.get<IStorageUsageDto>(`${this.urlBase}/used-storage`);
  }

  putJob(job: IJobDto) {
    return this.http.put<IJobDto>(this.urlBase, job);
  }
  deleteJob(jobId: number) {
    return this.http.delete(`${this.urlBase}/${jobId}/delete`);
  }
}
