<mat-drawer-container class="job-list-container" hasBackdrop="false" (scroll)="onScroll($event)">
  <mat-drawer-content>

    <div *ngIf="noMovieUploaded || noResultForSearch" class="no-result-container mat-typography">
      <img src="/assets/img/film-roll.svg" *ngIf="noResultForSearch" [@shakeOnEnter]="false" alt="film roll">
      <img src="/assets/img/camera.svg" *ngIf="noMovieUploaded && !noResultForSearch" [@rubberBandOnEnter]="false"
        alt="camera">
      <!-- <label *ngIf="noMovieUploaded">There are not yet movie upload, please use the button new movies</label> -->
      <button *ngIf="noMovieUploaded && !noResultForSearch" (click)="drawer.open()" class="add-movie-button"
        mat-raised-button color="primary">
        <mat-icon class="add-icon">add</mat-icon> New movie
      </button>
      <h1 *ngIf="noResultForSearch">Sorry there are no result matching for your search</h1>
    </div>

    <div class="card-container">
      <mat-card class="job-card" *ngFor="let job of jobs">
        <div class="poster-container">
          <div class="spinner-container" *ngIf="statusesInProgress.includes(job.status)">
            <div class="upload-progress">
              {{ job.percent ? job.percent : (job.status !== 'Created' && statusesInProgress.includes(job.status) ? 100
              : 0)}}%
            </div>
            <mat-icon class="upload-icon">file_upload</mat-icon>
            <mat-progress-spinner [mode]="'determinate'"
              [value]="job.percent ? job.percent : (job.status !== 'Created' && statusesInProgress.includes(job.status) ? 100: 0)"
              *ngIf="statusesInProgress.includes(job.status)" [diameter]="100">
            </mat-progress-spinner>
          </div>

          <img *ngIf="!statusesInProgress.includes(job.status) && !job.errorMessage" [src]="getCoverUrl(job)"
            (click)="openPlayer(job.resourceId, job.hasDrm)">
          <mat-icon class="preview-warning" *ngIf="job.errorMessage" mat-list-icon matTooltip="{{ job.errorMessage }}">
            warning</mat-icon>
        </div>
        <div class="job-info-container">
          <mat-card-content class="card-content">
            <div class="icon-bar">
              <mat-icon mat-list-icon color="warn" *ngIf="job.hasDrm">enhanced_encryption</mat-icon>
            </div>
            <mat-card-title-group>
              <ng-container class="container-title">
                <mat-card-title>

                  <div class="edit-container" [ngClass]="{'is-renaming': jobTitleEdition[job.id]?.isStarted}">
                    <div class="input-container" (click)="startJobTitleEdit(job)">
                      <input [ngClass]="{'is-renaming': jobTitleEdition[job.id]?.isStarted}" type="text"
                        [(ngModel)]="job.title" (readonly)="!jobTitleEdition[job.id]?.isStarted">
                    </div>

                    <div class="edit-icon-container" [hidden]="!jobTitleEdition[job.id]?.isStarted">
                      <mat-spinner *ngIf="jobTitleEdition[job.id]?.isSaving" [diameter]="20" class="uploader-status">
                      </mat-spinner>
                      <div *ngIf="!jobTitleEdition[job.id]?.isSaving">
                        <mat-icon class="pointer material-symbols-outlined" (click)="validateJobTitleEdit(job)">save
                        </mat-icon>
                        <mat-icon class="pointer material-symbols-outlined" (click)="cancelJobTitleEdit(job)">
                          disabled_by_default
                        </mat-icon>
                      </div>
                    </div>
                  </div>

                </mat-card-title>
                <mat-card-subtitle>{{ job.creationDate | date:'dd/MM/yyyy hh:mm:ss' }} {{job.owner? ('| '+job.owner) : ''}}
                </mat-card-subtitle>
              </ng-container>
            </mat-card-title-group>
            <mat-chip-list class="chip-list">
              <mat-chip color="primary" [selected]="statusesInProgress.includes(job.status)">
                <mat-spinner *ngIf="statusesInProgress.includes(job.status)" [diameter]="20" class="uploader-status">
                </mat-spinner>
                {{ getDisplayStatus(job.status) }}
              </mat-chip>
            </mat-chip-list>
          </mat-card-content>
          <div class="element-detail" [@detailExpand]="job == expandedElement ? 'expanded' : 'collapsed'">
            <mat-list>
              <mat-list-item>
                <mat-icon mat-list-icon>file_present</mat-icon>
                <div mat-line>
                  <h3>File name</h3>
                </div>
                <div mat-line>{{ job.title }}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-icon>height</mat-icon>
                <div mat-line>
                  <h3>Video Height</h3>
                </div>
                <div mat-line>{{ job.height }}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-icon>access_time</mat-icon>
                <div mat-line>
                  <h3>Duration</h3>
                </div>
                <div mat-line>{{ utils.formatSeconds(job.durationInSeconds) }}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-icon>compress</mat-icon>
                <div mat-line>
                  <h3>Format</h3>
                </div>
                <div mat-line>{{ job.format }}</div>
              </mat-list-item>
            </mat-list>
            <mat-list>
              <mat-list-item>
                <mat-icon mat-list-icon>note</mat-icon>
                <div mat-line>
                  <h3>Frame count</h3>
                </div>
                <div mat-line>{{ job.frameCount }}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-icon>local_movies</mat-icon>
                <div mat-line>
                  <h3>Frame rate</h3>
                </div>
                <div mat-line>{{ job.frameRate }}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-icon *ngIf="job.hasDrm">enhanced_encryption</mat-icon>
                <mat-icon mat-list-icon *ngIf="!job.hasDrm">no_encryption</mat-icon>
                <div mat-line>
                  <h3>DRM</h3>
                </div>
                <div mat-line>{{ job.hasDrm }}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-icon>local_movies</mat-icon>
                <div mat-line>
                  <h3>Resolutions</h3>
                </div>
                <div mat-line>
                  <mat-chip-list>
                    <mat-chip *ngFor="let resolution of job.resolutionList">{{ resolution.replace('Resolution', '')
                      }}</mat-chip>
                  </mat-chip-list>
                </div>
              </mat-list-item>
              <mat-list-item *ngIf="job.errorMessage">
                <mat-icon mat-list-icon matTooltip="{{ job.errorMessage }}">warning</mat-icon>
                <div mat-line></div>
                <div mat-line>An error occurred during the file conversion.</div>
              </mat-list-item>
            </mat-list>
          </div>

          <mat-divider inset class="job-divider"></mat-divider>
          <mat-card-actions class="card-actions">
            <button mat-flat-button *ngIf="job.canAppend" color="primary" (click)="openAppend(job)">
              <mat-icon>cloud_upload</mat-icon> Resume the upload
            </button>
            <button mat-button (click)="openPlayer(job.resourceId, job.hasDrm)">
              <mat-icon>play_arrow</mat-icon> Play
            </button>
            <!-- <button mat-button (click)="copyWatchUrl(job.id)">
              <mat-icon>content_copy</mat-icon> Copy link
            </button> -->
            <button mat-button (click)="expandedElement = expandedElement === job ? null : job">
              <mat-icon>video_settings</mat-icon> Details
            </button>
            <button class="red" mat-button (click)="deleteJob(job)" [disabled]="isJobDeleting[job.id]">
              <mat-icon *ngIf="!isJobDeleting[job.id]">remove_from_queue</mat-icon>
              <mat-icon *ngIf="isJobDeleting[job.id]">
                <mat-spinner color="#fff" diameter="20"></mat-spinner>
              </mat-icon>
              Delete
            </button>
          </mat-card-actions>
        </div>
      </mat-card>
      <ngx-skeleton-loader *ngIf="isLoadingResults" count="5" animation="pulse"
        [theme]="{ 'border-radius' : '4px', 'height' : '170px', 'margin-bottom' : '5px', 'background-color' : '#424242'}">
      </ngx-skeleton-loader>
      <div class="no-more-data-container" [@bounceOnEnter]="false"
        *ngIf="noMoreData && !noResultForSearch && !noMovieUploaded">
        <hr>
        <div class="icon-container">
          <mat-icon>task_alt</mat-icon>
        </div>
        <p>All the movies have been loaded</p>
      </div>
    </div>

  </mat-drawer-content>

  <mat-drawer #drawer mode="over" position="end">
    <div class="drawer-content">
      <h2>Upload your movie</h2>
      <app-upload-form *ngIf="drawer.opened" (uploadLaunchedEvent)="uploadedStart(drawer)"></app-upload-form>
      <button type="button" mat-raised-button (click)="drawer.toggle()">Close</button>
    </div>

  </mat-drawer>
</mat-drawer-container>