<mat-drawer-container hasBackdrop="false">
  <mat-drawer-content>
    <h1>Your uploaded files</h1>

    <div class="filter-form">
      <mat-form-field appearance="standard" class="search-field">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Start typing to search">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="standard" class="sort-field">
        <mat-label>Sort by</mat-label>
        <select matNativeControl required>
          <option value="last-added">Last added</option>
          <option value="name">Name</option>
          <option value="most-popular">Most popular</option>
        </select>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <div class="movie-card-container">
      <mat-card class="film-card" *ngFor="let movie of movies">
        <img mat-card-image src="{{ movie.poster }}" alt="Photo of {{ movie.internationalTitle }}">
        <mat-card-content>
          <mat-card-title>{{ movie.internationalTitle }}</mat-card-title>
          <mat-card-subtitle>{{ movie.releaseDate }}</mat-card-subtitle>
          <mat-chip-list>
            <mat-chip>{{ movie.fileSize / 1000 | number }} Mb</mat-chip>
            <mat-chip>{{ 'Added the ' + movie.createdAt }}</mat-chip>
          </mat-chip-list>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="primary" (click)="openPlayer(movie.poster)">
            <mat-icon>play_arrow</mat-icon> PLAY
          </button>
          <button mat-button color="primary" (click)="drawer.toggle()">
            <mat-icon>code</mat-icon> INTEGRATION CODE
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-drawer-content>
  <mat-drawer #drawer mode="over" position="end">
    <h2>Get your integration code</h2>
    <p>Insert the following line in your website head tag:</p>
    <p>
      <mat-form-field>
        <input matInput type="text" disabled [(ngModel)]="integrationLink">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" [cdkCopyToClipboard]="integrationLink">
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
    </p>

    <p>Insert the following line in your website body tag:</p>
    <p>
      <mat-form-field>
        <textarea matInput type="text" disabled [(ngModel)]="integrationScript"></textarea>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" [cdkCopyToClipboard]="integrationScript">
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
    </p>
    <button type="button" mat-raised-button (click)="drawer.toggle()">Close</button>
  </mat-drawer>
</mat-drawer-container>