import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFilmDataResultSetDto } from '../dtos/filmDataResultSetDto';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilmDataService {

  private urlBase = environment.tmbdApiUrl + 'search/movie?api_key=c7d702256faa65dc65f1d22cb40f3169';

  constructor(private http: HttpClient) {
  }

  /**
   * Use this service to retrieve the film details from an online database.
   */
  getDataForFilm(name: string): Observable<IFilmDataResultSetDto> {
    const url = this.urlBase + '&query=' + name;
    return this.http.get<any>(url);
  }
}
