// Manages the cognito authentication, see: https://www.npmjs.com/package/amazon-cognito-identity-js for more details


import { Injectable } from '@angular/core';
import 'cross-fetch/polyfill';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private session = new ReplaySubject<CognitoUserSession>(1);
  private cognitoUser: CognitoUser;

  private poolData = {
    UserPoolId: environment.cognitoUserPoolId,
    ClientId: environment.cognitoClientId,
    Paranoia: 7
  };

  constructor() {
    const userPool = new CognitoUserPool(this.poolData);
    this.cognitoUser = userPool.getCurrentUser();
    this.loadOrRefreshSessionFromCognito().then((session) => this.session.next(session));
  }

  loadOrRefreshSessionFromCognito(): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      if (this.cognitoUser !== null) {
        this.cognitoUser.getSession((error: any, session: CognitoUserSession) => {
          if (error) {
            console.log(error);
            resolve(null);
            return;
          }
          this.session.next(session);
          resolve(session);
        });
      }
      else {
        resolve(null);
      }
    });
  }

  createCognitoUser(username: string): CognitoUser {
    const userPool = new CognitoUserPool(this.poolData);
    const userData = {
      Username: username,
      Pool: userPool,
    };
    return new CognitoUser(userData);
  }

  login(username: string, password: string): Promise<CognitoUserSession> {

    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(
      authenticationData
    );
    this.cognitoUser = this.createCognitoUser(username);

    return new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          this.session.next(session);
          resolve(session);
        },
        onFailure: err => {
          // User authentication was not successful
          reject(err.message || JSON.stringify(err));
          this.session.next(null);
        },
        newPasswordRequired: (userAttributes) => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete authentication.
          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          let sessionUserAttributes: any;
          sessionUserAttributes = {
            userAttributes,
            username
          };

          reject(sessionUserAttributes);
        }
      });
    });
  }

  forgotPassword(username: string): Promise<void> {

    this.cognitoUser = this.createCognitoUser(username);

    return new Promise((resolve, reject) => {
      this.cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
          console.log('CodeDeliveryData from forgotPassword: ' + data);
          resolve();
        },
        onFailure: function (err) {
          alert(err.message || JSON.stringify(err));
          reject();
        }
      });
    });
  }

  resetPasswordWithCode(username: string, verificationCode: string, newPassword: string): Promise<void> {

    this.cognitoUser = this.createCognitoUser(username);

    return new Promise((resolve, reject) => {
      this.cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          console.log('Password confirmed!');
          resolve();
        },
        onFailure(err) {
          alert("Failed to reset password");
          console.log(err.message);
          resolve();
        },
      });
    });
  }

  resetPassword(newPassword: string, resetPasswordData: any): Promise<CognitoUserSession> {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      this.cognitoUser.completeNewPasswordChallenge(newPassword, resetPasswordData.userAttributes, {
        onSuccess: (session) => {
          this.session.next(session);
          resolve(session);
        },
        onFailure: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  logout(): void {
    // remove user from local storage to log user out
    this.cognitoUser.signOut();
    this.session.next(null);
  }

  getJwtToken(): Observable<string> {

    return this.getSession().pipe(map((session =>
      session != null ? session.getAccessToken().getJwtToken() : null
    )));

  }

  getUsername(): Observable<string> {
    return this.getSession().pipe(map((session =>
      session != null ? session.getAccessToken().payload.username : ''
    )));
  }

  getSession(): Observable<CognitoUserSession> {

    // when the session is not valid, we try to refresh it !
    return this.session.pipe(

      switchMap((session) => {

        if (session != null && !session.isValid && this.cognitoUser != null) {
          return from(this.loadOrRefreshSessionFromCognito());
        }

        return of(session);
      }
      ));
  }

  isLoggedIn(): Observable<boolean> {
    return this.getSession().pipe(map((session) => session != null && session.isValid()));
  }
}
