import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IJobDto } from '../../dtos/jobDto';
import { JobsService } from '../../services/jobs.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { forkJoin, interval, of, Subject, Subscription, timer } from 'rxjs';
import { PlayerComponent, PlayerDialogData } from '../../component/player/player.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UtilsService } from '../../services/utils.service';
import { WINDOW } from '../../providers/window.provider';
import { debounceTime, distinctUntilChanged, first, switchMap, map, finalize } from 'rxjs/operators';
import { UploadFormComponent } from '../../component/upload-form/upload-form.component';
import { UploadsService } from '../../services/uploads.service';
import { storedJobStatusDto } from 'src/app/dtos/storedJobStatusDto';
import { ResumeUploadModalComponent } from 'src/app/component/resume-upload-modal/resume-upload-modal.component';
import { SearchService } from 'src/app/services/search.service';
import { MatDrawer } from '@angular/material/sidenav';
import { DrawerService } from 'src/app/services/mat-drawer.service';
import { ITokenDto } from 'src/app/dtos/tokenDto';
import { bounceOnEnterAnimation, rubberBandOnEnterAnimation, shakeOnEnterAnimation } from 'angular-animations';
import { ConfirmModalComponent } from 'src/app/component/confirm-modal/confirm-modal.component';

interface IJobTitleEdition {
  isStarted: boolean;
  isSaving: boolean;
  originalTitle: string;
}
export enum BinaryExpression {
  And = "And",
  Or = "Or"
}

@Component({
  selector: 'app-job-list-page',
  templateUrl: './job-list-page.component.html',
  styleUrls: ['./job-list-page.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    bounceOnEnterAnimation(),
    rubberBandOnEnterAnimation(),
    shakeOnEnterAnimation()
  ],
})
export class JobListPageComponent implements OnInit, OnDestroy {

  public displayedColumns: string[] = ['status', 'movie', 'title', 'createdAt', 'action'];
  public statusesInProgress: string[] = ['Created', 'Pending', 'Progressing', 'Submitted'];
  public jobs: IJobDto[] = [];
  public pendingJobs: IJobDto[] = [];
  public progressingJobs = 0;
  public jobsToAppend: storedJobStatusDto[] = [];
  public isLoadingResults = false;
  public expandedElement: IJobDto | null;
  public from = 0;
  public take = 10;
  public noMoreData = false;
  public isJobDeleting: boolean[] = [];
  public jobTitleEdition: IJobTitleEdition[] = [];
  private filter: any = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(UploadFormComponent) uploadForm: UploadFormComponent;
  @ViewChild('drawer', { static: true }) matDrawer!: MatDrawer;
  private timerSubscription: Subscription;
  public searchValue$ = new Subject<string>();
  public noMovieUploaded = false;
  public noResultForSearch = false;

  constructor(
    private jobsService: JobsService,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private snackbar: MatSnackBar,
    public utils: UtilsService,
    public uploadService: UploadsService,
    private searchService: SearchService,
    private drawerService: DrawerService,
    @Inject(WINDOW) private window: Window

  ) {
    this.uploadService.uploadProgressesChange.subscribe((item) => {
      const job = this.jobs.find(jobElement => jobElement.id === item.id);
      if (job) {
        Object.assign(job, item);
      }
    });
  }


  ngOnInit(): void {

    this.jobsService.getUsedStorage();

    this.drawerService.setDrawer(this.matDrawer);
    this.jobsToAppend = this.uploadService.getJobStatus();

    this.loadJobs(0, this.take, true);
    this.searchService.searchValue$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          this.noResultForSearch = false;
          if (searchTerm && searchTerm.length > 0) {
            this.filter.title = searchTerm;
            this.filter.owner = searchTerm;
            this.filter.binaryExpression = BinaryExpression.Or.toString();
          } else {
            delete this.filter.title;
            delete this.filter.owner;
            delete this.filter.binaryExpression;

            this.noMoreData = false;
            return this.jobsService.getJobsPaginated(0, this.take, '-creationDate', this.filter).pipe(first());
          }
          this.noMoreData = false;
          return this.jobsService.getJobsPaginated(0, this.take, 'title', this.filter).pipe(first(), map(result => {
            this.noResultForSearch = result.length === 0;
            return result;
          }));
        })
      )
      .subscribe((data) => {
        this.from = 0;
        this.enhanceJobItem(data, 0, this.take);
      });
    window.addEventListener('scroll', this.onScroll, true);
  }

  loadJobs(from: number, take: number, init: boolean = false): void {

    this.isLoadingResults = true;

    this.jobsService.getJobsPaginated(from, take, '-creationDate', this.filter).pipe(first()).subscribe((data) => {
      if (init) {
        if (!data.length) {
          this.noMovieUploaded = true;
          this.isLoadingResults = false;
          return;
        }
        const jobsToAppend = this.uploadService.getJobStatus();
        data.forEach(job => {
          const jobToAppend = jobsToAppend.find(jobToAppend => {
            return job.id === jobToAppend.id;
          });

          if (jobToAppend) {
            job.percent = Math.ceil((jobToAppend.urls.filter(g => g.uploaded).length / jobToAppend.urls.length) * 100);
          }
          job.canAppend = jobToAppend !== undefined;
        });
      }


      this.enhanceJobItem(data, from, take);
    });
  }

  enhanceJobItem(data: IJobDto[], from: number, take: number): void {
    if (data.length < take) { this.noMoreData = true; }

    if (from === 0) {
      this.jobs = data;
    } else {
      this.jobs = [...this.jobs, ...data];
    }


    const jobsStatus = this.uploadService.getJobStatus();
    jobsStatus.map(jobStatus => {
      const job = this.jobs.find(jobElement => jobElement.id === jobStatus.id);
      if (job) {
        Object.assign(job, jobStatus);
      }
    });

    this.progressingJobs = data.filter((job: IJobDto) => this.statusesInProgress.includes(job.status)).length;
    this.isLoadingResults = false;
  }

  openPlayer(resourceId: string, hasRdm: boolean = false): void {
    if (hasRdm) {
      this.jobsService.getMovieToken(resourceId).subscribe((token: ITokenDto) => {
        this.openPlayerDial({
          resourceId,
          displayCloseButton: true,
          token: token.token
        });
      });
    } else {
      this.openPlayerDial({
        resourceId,
        displayCloseButton: true
      });
    }
  }

  openPlayerDial(data: PlayerDialogData): void {
    this.dialog.open(PlayerComponent, {
      data,
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      panelClass: 'player-dialog',
      id: 'modal-player'
    });
  }

  openAppend(job: IJobDto): void {
    const storedJob = this.jobsToAppend.find((storedJobStatus) => storedJobStatus.id === job.id);
    this.dialog.open(ResumeUploadModalComponent, {
      data: {
        storedJob
      },
      id: 'modal-append',
      height: 'auto',
      width: '400px',
    }).afterClosed().subscribe((isAppend) => {
      if (isAppend) {
        job.canAppend = false;
        this.autoRefreshJobInProgress();
      }
    });
  }

  updateJobInProgress(): void {
    const jobsInProgress = this.jobs.filter((job: IJobDto) => this.statusesInProgress.includes(job.status)).map(job => {
      return this.jobsService.getJobById(job.id);
    });

    forkJoin(jobsInProgress).subscribe(result => {
      result.forEach(job => {
        const index = this.jobs.indexOf(this.jobs.find(g => g.id == job.id));
        if (index >= 0) {
          this.jobs[index] = Object.assign(this.jobs[index], job);
        }
        this.progressingJobs = this.jobs.filter((job: IJobDto) => this.statusesInProgress.includes(job.status)).length;
      });
    });
  }

  autoRefreshJobInProgress(): void {
    const observable = interval(2000);
    this.timerSubscription = observable.subscribe(() => {
      if (this.progressingJobs > 0 || (this.uploadForm && this.uploadForm.uploadStarted)) {
        this.updateJobInProgress();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    window.removeEventListener('scroll', this.onScroll, true);
  }

  getCoverUrl(job: IJobDto): string {
    return environment.s3OutputDirectory + job.resourceId + '/Images/' + job.resourceId + '_poster.0000001.jpg';
  }


  startJobTitleEdit(job: IJobDto): void {
    this.jobTitleEdition[job.id] = {
      isStarted: true,
      isSaving: false,
      originalTitle: job.title
    }
  }

  cancelJobTitleEdit(job: IJobDto) {
    job.title = this.jobTitleEdition[job.id].originalTitle;
    delete this.jobTitleEdition[job.id];
  }

  validateJobTitleEdit(job: IJobDto): void {
    this.jobTitleEdition[job.id].isSaving = true;
    this.jobsService.putJob(job).subscribe(
      {
        complete: () => delete this.jobTitleEdition[job.id],
        error: () => {
          this.snackbar.open('Error, sorry we could not save the job, please retry later or call your administrator', 'close', { duration: 7000 });
          this.cancelJobTitleEdit(job);
        }
      });
  }

  deleteJob(job: IJobDto): void {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        text: `Are you sure you want to delete the movie : '${job.title}' ?`,
        onValid: () => {
          this.isJobDeleting[job.id] = true;
          this.jobsService.deleteJob(job.id).pipe(finalize(() => delete this.isJobDeleting[job.id])).subscribe({
            complete: () => {
              this.loadJobs(0, (this.from + this.take));
            },
            error: () => {
              this.snackbar.open('Eror, sorry we could not delete the job, please retry later or call your administrator', 'close', { duration: 7000 });
            }
          });
        }
      },
      id: 'modal-append',
      height: 'auto',
      width: '400px',
    });
  }

  getDisplayStatus(status: string): string {
    let displayStatus = null;
    switch (status) {
      case 'Progressing':
        displayStatus = 'Encoding';
        break;
      case 'Created':
      case 'Pending':
      case 'Submitted':
      case 'Error':
        displayStatus = status;
        break;
      case 'Complete':
        displayStatus = 'Ready';
        break;
    }
    return displayStatus;
  }

  resolveWatchUrl(uniqueId: number): string {
    return this.window.location.protocol + '//' + this.window.location.host + '/player/' + uniqueId;
  }

  copyWatchUrl(uniqueId: number): void {
    const url = this.resolveWatchUrl(uniqueId);
    this.clipboard.copy(url);
    this.snackbar.open('The url has been copied. You can paste it anywhere.', 'close', { duration: 7000 });
  }

  showLoading(): void {
    timer(200).subscribe(() => {
      if (!this.isLoadingResults) {
        this.isLoadingResults = true;
      }
    });
  }

  uploadedStart(drawer: any): void {
    drawer.toggle();
    this.loadJobs(0, (this.from + this.take));
    this.autoRefreshJobInProgress();
    this.noMovieUploaded = false;
  }

  onScroll = (event: any) => {
    if (!this.noMoreData && !this.isLoadingResults) {
      const scroll = event.target;
      if (scroll.scrollTop >= (scroll.scrollHeight / 50)) {
        this.from += this.take;
        this.loadJobs(this.from, this.take);
      }
    }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler($event: any): void {
    if (this.uploadService.uploadsInProgress > 0) {
      $event.preventDefault();
      $event.returnValue = false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event: any): void {
    if (this.uploadService.uploadsInProgress > 0) {
      $event.preventDefault();
      $event.returnValue = false;
    }
  }
}
