import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadsService } from 'src/app/services/uploads.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { storedJobStatusDto } from '../../dtos/storedJobStatusDto';

@Component({
  selector: 'app-modal-append',
  templateUrl: './resume-upload-modal.component.html',
  styleUrls: ['./resume-upload-modal.component.scss']
})
export class ResumeUploadModalComponent implements OnInit {

  public appendUploadForm: FormGroup;
  private currentFile: File;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { storedJob: storedJobStatusDto },
    public dialogRef: MatDialogRef<ResumeUploadModalComponent>,
    private uploadService: UploadsService) { }

  ngOnInit(): void {
    this.appendUploadForm = new FormGroup({
      file: new FormControl('', [Validators.required, Validators.pattern('^.+' + this.data.storedJob.filename + '$')])
    });
  }

  appendUpload(): void {
    const arrayStringUrl = this.data.storedJob.urls.map(url => { return url.url });

    const firstUrlToAppend = this.data.storedJob.urls.find(url => url.uploaded === false);
    if (!firstUrlToAppend) {
      console.warn('missing url to append upload');
      return;
    }

    this.uploadService.uploadParts(
      arrayStringUrl,
      this.data.storedJob.id,
      this.currentFile,
      firstUrlToAppend.partNumber,
      this.data.storedJob.partETags
    );
    this.dialogRef.close(true);
  }

  onFileSelected(event: any): void {
    this.currentFile = event.target.files[0];
  }

  matches(form: AbstractControl) {
    return form.get('file').value && form.get('file').value === this.data.storedJob.filename ? null : { equals: true };
  }
}
