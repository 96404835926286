<mat-card>
  <mat-card-content>
    <h1 *ngIf="currentForm == FormType.Login">LOG IN</h1>
    <h1 *ngIf="currentForm == FormType.ResetPassword">CHANGE YOUR PASSWORD</h1>
    <h1 *ngIf="currentForm == FormType.ResetPasswordWithCode">FORGOTTEN PASSWORD</h1>

    <p *ngIf="currentForm == FormType.Login">Please enter your credentials in the form below to access the application.
    </p>
    <p *ngIf="currentForm == FormType.ResetPassword">You tried to access the application with your temporary password.
      Please change it using the form below.</p>
    <p *ngIf="currentForm == FormType.ResetPasswordWithCode">Fill the following form to reset your password</p>

    <form [formGroup]="loginForm" *ngIf="currentForm == FormType.Login">
      <p>
        <mat-form-field>
          <mat-label>Username</mat-label>
          <input matInput type="text" formControlName="username" autocomplete>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" autocomplete>
          <mat-error *ngIf="loginForm.get('password').hasError('system')">
            {{ loginForm.get('password').getError('system') }}
          </mat-error>
        </mat-form-field>
      </p>
      <p class="forgotten-password-button-container">
        <a (click)="forgottenPassword()" href="javascript:return false;">Forgot password?</a>
      </p>
      <p class="button-container">
        <button mat-raised-button color="primary" (click)="authenticate()">Submit</button>
      </p>
    </form>

    <form [formGroup]="changePasswordForm" *ngIf="currentForm == FormType.ResetPassword">
      <p>
        <mat-form-field>
          <mat-label>New Password</mat-label>
          <input matInput type="password" formControlName="password" autocomplete>
        </mat-form-field>
      </p>
      <p class="button-container">
        <button mat-raised-button color="primary" (click)="resetPassword()">Change Password</button>
      </p>
    </form>

    <form [formGroup]="forgottenPasswordForm" *ngIf="currentForm == FormType.ResetPasswordWithCode">
      <p>
        <mat-form-field>
          <mat-label>The code you received by email</mat-label>
          <input matInput type="text" formControlName="code" id="verificationCode" autocomplete="off">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <mat-label>Username</mat-label>
          <input matInput type="text" formControlName="usernameToReset" id="usernameToReset" autocomplete="off">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <mat-label>New Password</mat-label>
          <input matInput type="password" formControlName="newPassword" id="newPassword" autocomplete="off">
        </mat-form-field>
      </p>
      <p class="button-container">
        <button mat-raised-button color="primary" (click)="resetPasswordWithCode()">Change Password</button>
      </p>
    </form>


  </mat-card-content>
</mat-card>