import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { PlayerSeekBarFactory } from './player.seekbar';

export interface PlayerDialogData {
  resourceId: string;
  displayCloseButton: boolean;
  token?: string;
}

declare var shaka: any;

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, AfterViewInit {

  private manifestUri = environment.s3OutputDirectory + 'video.mpd';
  public posterUri: string;
  private token: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PlayerDialogData) {
    this.posterUri = environment.s3OutputDirectory + data.resourceId + '/Images/' + data.resourceId + '_poster.0000001.jpg';
  }

  ngAfterViewInit(): void {
    this.initPlayer();
  }

  ngOnInit(): void {


    // document.addEventListener('shaka-ui-loaded', this.initPlayer);
    // Needed for shaka to init with video element in dom
    dispatchEvent(new Event('load'));
  }


  onErrorEvent(event: any): void {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError(error: any): void {
    // Log the error.
    console.error('Error code', error.code, 'object', error);
  }

  getMyContentId(skd: string): string {

    console.log("Get content id from skd:", skd);

    const regex = /^[^;]*;(.*)$/gm;
    const m = regex.exec(skd);

    if (m !== null) {
      return m[1];
    }

    return '';
  }

  configurePlayerForFairplay(player: any): void {

    let assetId: string = null;
    player.configure('drm.initDataTransform', (initData: any, initDataType: any) => {
      if (initDataType !== 'skd') {
        return initData;
      }

      // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
      const skdUri: string = shaka.util.StringUtils.fromBytesAutoDetect(initData);
      const contentId = this.getMyContentId(skdUri);
      assetId = contentId;
      const cert = player.drmInfo().serverCertificate;
      return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
    });

    player
      .getNetworkingEngine()
      .registerRequestFilter((type: any, request: any) => {
        if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
          return;
        }
        // Modify url request to include assetId, token, ....

        request.uris[0] = `${request.uris[0]}${assetId}?CustomValue=${this.data.token}`;
      });
  }


  /**
   * Check if the current platform is from Apple.
   *
   * Returns true on all iOS browsers and on desktop Safari.
   *
   * Returns false for non-Safari browsers on macOS, which are independent of Apple.
   */
  isApple(): boolean {
    return !!navigator.vendor && navigator.vendor.includes('Apple');
  }

  async initPlayer(): Promise<void> {


    const resourceId = this.data.resourceId;
    const appleManifestUri = environment.s3OutputDirectory + resourceId + '/Apple/' + resourceId + '.m3u8';
    this.manifestUri = environment.s3OutputDirectory + resourceId + '/' + resourceId + '.mpd';

    // Create a Player instance.
    const video: any = document.getElementById('video');

    const ui: any = video['ui'];
    const controls = ui.getControls();
    const player = controls.getPlayer();

    // Attach player to the window to make it easy to access in the JS console.

    // Add the certificate file
    const req = await fetch(environment.fairplayCertUrl);
    const cert = await req.arrayBuffer();
    player.configure('drm.advanced.com\\.apple\\.fps\\.1_0.serverCertificate', new Uint8Array(cert));

    // Listen for error events.
    player.addEventListener('error', this.onErrorEvent);

    let previewInfoUrl = environment.s3OutputDirectory + resourceId + '/Preview/sprite.json'
    let previewImageUrl = environment.s3OutputDirectory + resourceId + '/Preview/sprite.jpg'

    let previewInfo = null;
    if (previewInfoUrl) {
      try {
        previewInfo = await (await fetch(previewInfoUrl)).json();
      }
      catch (e) {
        console.log(e);
      }
    }
    shaka.ui.Controls.registerSeekBar(new PlayerSeekBarFactory(previewInfo, previewImageUrl));

    player.configure({
      drm: {
        servers: {
          'com.widevine.alpha': `${environment.widewineProxyUrl}&st=${this.data.token}`,
          'com.microsoft.playready': `${environment.playreadyPreAuthUrl}&st=${this.data.token}`,
          'com.apple.fps.1_0': `${environment.fairplayLicenseUrl}`, // Token is added later
        }
      }
    });

    if (this.isApple()) {
      this.configurePlayerForFairplay(player);
      this.manifestUri = appleManifestUri;
    }

    const config = {
      'overflowMenuButtons': ['quality']
    };
    ui.configure(config);

    // Try to load a manifest.
    // This is an asynchronous process.
    try {
      await player.load(this.manifestUri);
      // This runs if the asynchronous load is successful.
    } catch (e) {
      // onError is executed if the asynchronous load fails.
      this.onError(e);
    }
  }
}
